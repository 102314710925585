export const VendorIdDefault = process.env.REACT_APP_API_VENDOR_DEFAULT;
export const MaxItemsPerPage = 100;
export const MaxPagePerSection = 25;
export const ACCEPT_BUSINESS_LICENSE_FILE = '.pdf, image/*';
export const ACCEPT_ONLY_IMAGE_FILE = 'image/*';
export const ACCEPT_ONLY_CSV_FILE = '.csv, .xls, .xlsx';
export const DOCS_FILE_EXTENSION = ['pdf', 'doc', 'docx', 'png', 'jpeg', 'jpg'];
export const TIME_ZONE_DEFAULT = 'Asia/Kuala_Lumpur';

export const QueryParamsKey = {
    PAGE: 'page',
    OFFSET: 'offset',
    TAKE: 'take',
    IS_PAGING: 'is_paging',
    KEYWORD: 'keyword',
    ID: 'id',
    FILTER_BY: 'filter_by',
    SORT_BY: 'sort_by',
    CUSTOMER_ID: 'cus_id',
    UCD_CODE: 'ucd_code',
    VENDOR_ID: 'vendor_id',
    ACCESS_TOKEN: 'access_token',
    FROM_DATE: 'from_date',
    TO_DATE: 'to_date',
    CHUNK_FILES: 'chunk_files',
    ACTION_TYPE: 'action_type',
    CONTACT_NO: 'contact_no'
};

export const ApiMethod = {
    Auth: {
        GetToken: 'api/1.0/auth/token',
        Login: 'api/1.0/auth/login',
        Logout: 'api/1.0/auth/logout',
        RequestOTPResetPassword: 'api/1.0/auth/request_otp/reset_password',
        ConfirmResetPassword: 'api/1.0/auth/confirm/reset_password',
    },
    Accounts: {
        ListAccounts: 'api/1.0/accounts',
        ListAccountsByVendorId: 'api/1.0/accounts/by_vendor',
        NewAccount: 'api/1.0/accounts',
        CreateListAccount: 'api/1.0/accounts/create_list',
        UpdateAccount: 'api/1.0/accounts',
        UpdateAccountStatus: 'api/1.0/accounts/update_status',
        GenerateNewApiKey: 'api/1.0/accounts/generate_api_key',
        Details: 'api/1.0/accounts/details',
        Profile: 'api/1.0/accounts/profile',
        UpdateProfile: 'api/1.0/accounts/profile/update_info',
        DeleteById: 'api/1.0/accounts/delete'
    },
    Customers: {
        NewCustomer: 'api/1.0/customers',
        UpdateCustomer: 'api/1.0/customers',
        UpdateStatus: 'api/1.0/customers/update_status',
        ListCustomers: 'api/1.0/customers',
        Details: 'api/1.0/customers/details',
        DetailsByContact: 'api/1.0/customers/details_by_contact?contact_no=%s',
        Verified: 'api/1.0/customers/verified',
        SyncFailingData: 'api/1.0/customers/sync_failing_data',
        RejectChangesCustomerInfo: 'api/1.0/customers/verify_info/%s/reject',
        DeleteById: 'api/1.0/customers/delete',
        RemoveVietPOMIdentity: 'api/1.0/customers/vietpom/%s/remove_identity/%s',
        AddVietPOMIdentity: 'api/1.0/customers/vietpom/%s/add_identity',
        FindCustomerFromVendor: 'api/1.0/customers/vendor/find_customer?vendor_id=%s&customer_code=%s&phone_number=%s',
        ImportVendorCustomersFromFile: 'api/1.0/customers/vendor/%s/import_customers',
        UpdateCustomersFromFile: 'api/1.0/customers/%s/update_customers_from_file',
        // Sub-users
        ListSubUsers: 'api/1.0/customers/%s/sub_users',
        CreateSubUser: 'api/1.0/customers/%s/sub_users',
        UpdateSubUser: 'api/1.0/customers/%s/sub_users/%s',
        DeleteSubUser: 'api/1.0/customers/%s/sub_users/%s',
    },
    Vendors: {
        ListVendors: 'api/1.0/vendors',
        NewVendor: 'api/1.0/vendors',
        UpdateVendor: 'api/1.0/vendors',
        Details: 'api/1.0/vendors/details',
        UpdateStatus: 'api/1.0/vendors/update_status',
        DeleteById: 'api/1.0/vendors/delete',
        ListCustomers: 'api/1.0/vendors/customers',
        GetVendorCustomerDetails: 'api/1.0/vendors/customers/details',
        UpdateVendorCustomer: 'api/1.0/vendors/customers/update_customer',
        DeleteVendorCustomerById: 'api/1.0/vendors/customers/delete',
    },
    Report: {
        TemplateFileVendorCustomers: 'api/1.0/report/template_files/vendor_customers',
        TemplateFileUpdateVendorCustomers: 'api/1.0/report/template_files/update_vendor_customers',
        GetDashboardSummary: 'api/1.0/report/dashboard/summary',
        GetHistoryImportCustomers: 'api/1.0/report/history/import_customers',
        CountCustomersImportFailed: 'api/1.0/report/history/count_customers_import_failed',
        ExportCustomersImportFailed: 'api/1.0/report/history/export_customers_import_failed',
        ExportUnifiedCustomersToFile: 'api/1.0/report/export_unified_customers'
    },
    Assets: {
        GetFile: 'assets/files?key_file=',
        GetDocumentFile: 'assets/get_document?key_file='
    },
    Settings: {
        ListConfiguration: 'api/1.0/settings',
        NewConfiguration: 'api/1.0/settings',
        CongfigurationDetails: 'api/1.0/settings/details',
        UpdateConfiguration: 'api/1.0/settings',
        UpdateConfigurationStatus: 'api/1.0/settings/%s/update_status',
        DeleteById: 'api/1.0/settings'
    },
};

export const CountryCodes = {
    VIETNAM_COUNTRY_CODE: '+84',
    SINGAPORE_COUNTRY_CODE: '+65',
    MALAYSIA_COUNTRY_CODE: '+60',
};

export const CountryAvailable = [
    // { name: 'Viet Nam', code: CountryCodes.VIETNAM_COUNTRY_CODE, isoCode: 'VN' },
    // { name: 'Singapore', code: CountryCodes.SINGAPORE_COUNTRY_CODE, isoCode: 'SG' },
    { name: 'Malaysia', code: CountryCodes.MALAYSIA_COUNTRY_CODE, isoCode: 'MY' }
];

/**
 * List all special vendor
 */
export const SpecialVendors = {
    VENDOR_DEFAULT: VendorIdDefault,
    VIETPOM: VendorIdDefault,
    HOANG_DUC: 'hoangduc-vn'
};

/**
 * Access level
 */
export const AccessLevels = {
    BASIC_LEVEL: "B",
    FULL_LEVEL: "F",

    GetLevelName(key) {
        let result = '';

        if (key == null || key === '') {
            result = 'Basic Account';
        } else {

            const allItems = AccessLevels.ListAvailable();

            for (let i = 0; i < allItems.length; i++) {
                if (allItems[i].key === key) {
                    result = allItems[i].name;
                    break;
                }
            }
        }

        return result;
    },
    ListAvailable() {
        return [
            {
                key: AccessLevels.BASIC_LEVEL,
                name: 'Basic Account'
            },
            {
                key: AccessLevels.FULL_LEVEL,
                name: 'Full Account'
            }
        ];
    }
};

/**
 * Roles.
 */
export const Roles = {
    SUPER_ADMIN: "SA",
    ADMIN: "A",
    VENDOR_ADMIN: "VA",
    DOCTOR: "DR",
    GetRoleName(roles) {
        switch (roles) {
            case Roles.SUPER_ADMIN:
                return 'Super Admin';

            case Roles.ADMIN:   // UCD Admin
                return 'Admin';

            case Roles.VENDOR_ADMIN:
                return 'Vendor Admin';

            case Roles.DOCTOR:
                return 'Doctor';

            default:
                return roles;
        }
    }
};

/**
 * Roles available.
 */
export const RolesAvailable = [
    { name: 'Admin', key: Roles.ADMIN },
    { name: 'Vendor Admin', key: Roles.VENDOR_ADMIN }
];

/**
 * Roles available (Only for super admin).
 */
export const SuperAdminRolesAvailable = [
    { name: 'Super Admin', key: Roles.SUPER_ADMIN },
    { name: 'Admin', key: Roles.ADMIN },
    { name: 'Vendor Admin', key: Roles.VENDOR_ADMIN }
];

export const PrefixDefault = [
    {
        keyword: 'Dr',
        name: 'Dr'
    },
    {
        keyword: 'Mr',
        name: 'Mr'
    },
    {
        keyword: 'Ms',
        name: 'Ms'
    },
    {
        keyword: 'Other',
        name: 'Other'
    }
];

/**
 * Forensic group default
 */
export const UserGroupDefault = {
    PHARMA_COMPANY: 'Pharma Company',
    PHARMACY: 'Pharmacy',
    CLINIC: 'Clinic',
    NON_PHARMACY_LICENSE: 'Non-Pharmacy license',
    ListAvailable() {
        return [
            {
                keyword: UserGroupDefault.PHARMA_COMPANY,
                // name: 'Công ty dược',
                name: 'Pharma Company'
            },
            {
                keyword: UserGroupDefault.PHARMACY,
                // name: 'Nhà thuốc / Quầy thuốc',
                name: 'Pharmacy'
            },
            {
                keyword: UserGroupDefault.CLINIC,
                // name: 'Phòng khám',
                name: 'Clinic'
            },
            {
                keyword: UserGroupDefault.NON_PHARMACY_LICENSE,
                // name: 'Khác (Nhà thuốc Đông Y, thẩm mỹ viện, spa, siêu thị,...)',
                name: 'Non-Pharmacy license'
            }
        ];
    },
    GetName(key) {
        let result = '';
        const allItems = UserGroupDefault.ListAvailable();

        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i].keyword === key) {
                result = allItems[i].name;
                break;
            }
        }

        return result;
    }
};

/**
 * Regions group default
 */
export const RegionsGroupDefault = {
    NORTH: 'North',     // 'North',
    CENTRAL: 'Central', // 'Central',
    SOUTH: 'South',     // 'South',
    GetName(key) {
        let result = '';
        const allItems = RegionsGroupDefault.ListAvailable();

        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i].keyword === key) {
                result = allItems[i].name;
                break;
            }
        }

        return result;
    },
    ListAvailable() {
        return [
            {
                keyword: RegionsGroupDefault.NORTH,
                name: 'North'
            },
            {
                keyword: RegionsGroupDefault.CENTRAL,
                name: 'Central'
            },
            {
                keyword: RegionsGroupDefault.SOUTH,
                name: 'South'
            }
        ];
    }
};

export const LocationFormat = {
    DISTRICT_NUMBER_PREFIX_VN: 'Quận',
    WARD_NUMBER_PREFIX_VN: 'Phường',
};

/**
 * Location priority default
 */
export const LocationPriorityDefault = {
    VN_HCM: {
        CodeCity: 79,
        NameCity: 'Hồ Chí Minh',
    },
    VN_HN: {
        CodeCity: 1,
        NameCity: 'Hà Nội',
    },
    ListAvailable() {
        return [
            LocationPriorityDefault.VN_HCM,
            LocationPriorityDefault.VN_HN
        ];
    }
};

export const ActionOnRecord = {
    DETAILS: 'details',
    EDIT: 'edit',
    DELETE: 'delete',
    SYNC_FAILING_DATA: 'sync_failing_data',
    ON_CREATE: 'on_create',
    ON_UPDATE: 'on_update'
};

export const VerificationStatus = {
    APPROVED: "A",
    PENDING: "P"
};

export const UserStatus = {
    ACTIVE: 'A',
    DISABLED: 'D'
};

export const ConfigStatus = {
    ACTIVE: 'A',
    DISABLED: 'D'
};

export const ConfigStatusList = [
    { title: 'Active', key: ConfigStatus.ACTIVE, color: '' },
    { title: 'Disabled', key: ConfigStatus.DISABLED, color: 'text-disabled' }
];

export const UserStatusList = [
    { title: 'Active', key: UserStatus.ACTIVE, color: '' },
    { title: 'Disabled', key: UserStatus.DISABLED, color: 'text-disabled' }
];

export const VerificationStatusList = [
    { title: 'Approved', key: VerificationStatus.APPROVED },
    { title: 'Pending', key: VerificationStatus.PENDING }
];

// Admin Management
export const AccountTabKeys = {
    BASIC_INFORMATION: 'basic_information',
    VENDOR_INFORMATION: 'vendor_information'
};

// Customer management
export const CustomerTabKeys = {
    BASIC_INFORMATION: 'basic_information',
    BUSINESS_ADDRESS: 'business_address',
    SHIPPING_ADDRESS: 'shipping_address',
    BILLING_ADDRESS: 'billing_address',
    REF_ID: 'ref_id',
    SUB_USERS: 'sub_users',
    USER_INFORMATION: 'user_information'
};

// Vendor Management
export const VendorTabKeys = {
    BASIC_INFORMATION: 'basic_information',
    ADMIN_ACCOUNTS: 'admin_accounts'
};

export const FilterKeys = {
    ALL: 'all',
    REQUEST_KYC: 'request_kyc',
    FAILING_SENDING_DATA: 'failing_sending_data',
    BY_CREATED_DATE: 'by_created_date',
    ACTIVE: 'active',
    INACTIVE: 'inactive'
};

export const SortByRequestedDateDefault = [
    { title: 'Requested date', key: 'requested_date' }
];

export const SortByApprovedDateDefault = [
    { title: 'Approved date', key: 'approved_date' }
];

export const SortByDefault = [
    { title: 'Alphabetical', key: 'alphabetical' },
    { title: 'Status', key: 'status' }
];

export const FilterDefault = [
    { title: 'All', key: FilterKeys.ALL }
];

export const FilterByCreatedDate = [
    { title: 'By created date', key: FilterKeys.BY_CREATED_DATE }
];

export const FilterCustomersError = [
    { title: 'Request KYC', key: FilterKeys.REQUEST_KYC },
    { title: 'Failing sending data', key: FilterKeys.FAILING_SENDING_DATA, color: 'text-danger' }
];

// Filter unified customers
export const FilterCustomers = [
    ...FilterDefault,
    { title: 'Active', key: FilterKeys.ACTIVE },
    { title: 'Inactive', key: FilterKeys.INACTIVE, color: 'text-disabled' },
    { title: 'Approved', key: VerificationStatus.APPROVED },
    { title: 'Pending', key: VerificationStatus.PENDING }
];

// Filter vendor customers
export const FilterVendorCustomers = [
    ...FilterDefault,
    { title: 'Approved', key: VerificationStatus.APPROVED },
    { title: 'Pending', key: VerificationStatus.PENDING }
];

export const FilterVendors = [
    ...FilterDefault,
    { title: 'Active', key: UserStatus.ACTIVE },
    { title: 'Disabled', key: UserStatus.DISABLED }
];

export const TabsCustomerNonRefIDOnlyView = [
    {
        name: "General Information",
        key: CustomerTabKeys.BASIC_INFORMATION
    },
    // {
    //     name: "Billing Address",
    //     key: CustomerTabKeys.BILLING_ADDRESS
    // },
    {
        name: "Delivery Address",
        key: CustomerTabKeys.SHIPPING_ADDRESS
    }
];

export const TabsCustomerNonRefID = [
    {
        name: "General Information",
        key: CustomerTabKeys.BASIC_INFORMATION,
        segments: [
            {
                name: "Business Address",
                key: CustomerTabKeys.BUSINESS_ADDRESS
            },
            // {
            //     name: "Billing Address",
            //     key: CustomerTabKeys.BILLING_ADDRESS
            // },
            {
                name: "Delivery Address",
                key: CustomerTabKeys.SHIPPING_ADDRESS
            }
        ]
    }
];

export const TabsCustomerRefID = [
    {
        name: "Ref. ID",
        key: CustomerTabKeys.REF_ID
    }
];

export const AllTabsCustomerInfo = [
    ...TabsCustomerNonRefID,
    {
        name: "Ref. ID",
        key: CustomerTabKeys.REF_ID
    }
];

export const AllTabsCustomerForVendor = [
    ...TabsCustomerNonRefID
];

export const TabsVendorAdminAccounts = [
    {
        name: "Admin Accounts",
        key: VendorTabKeys.ADMIN_ACCOUNTS
    }
];

export const AllTabsVendorDetails = [
    {
        name: "General Information",
        key: VendorTabKeys.BASIC_INFORMATION
    }
];

export const AllTabsVendorAdminDetails = [
    {
        name: "General Information",
        key: AccountTabKeys.BASIC_INFORMATION
    },
    {
        name: "Vendor Information",
        key: AccountTabKeys.VENDOR_INFORMATION
    }
];

export const AllTabsUserDetails = [
    {
        name: "General Information",
        key: AccountTabKeys.BASIC_INFORMATION
    }
];

export const TabsCustomerAccounts = [
    {
        name: "Sub-users",
        key: CustomerTabKeys.SUB_USERS
    }
];

export const AllTabsSubUser = [
    {
        name: "User Information",
        key: CustomerTabKeys.USER_INFORMATION
    }
];

export const OptionMenuDeleteItem = [
    { title: 'Delete', key: ActionOnRecord.DELETE, color: 'text-danger' },
];

export const OptionMenuSyncFailingDataItem = [
    { title: 'Sync failing data', key: ActionOnRecord.SYNC_FAILING_DATA, color: 'text-danger' },
];

export const RoleOfSubUserDefault = {
    // Doctor, Purchaser, Pharmacist, Nurse, Finance
    DOCTOR: 'Doctor',
    // PURCHASER: 'Purchaser',
    PHARMACIST: 'Pharmacist',
    // NURSE: 'Nurse',
    // FINANCE: 'Finance',
    // BUSINESS_OWNER: 'Business Owner',
    // MANAGER: 'Manager',
    GetName(key) {
        let result = '';
        const allItems = RoleOfSubUserDefault.ListAvailable();

        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i].keyword === key) {
                result = allItems[i].name;
                break;
            }
        }

        return result;
    },
    ListAvailable() {
        return [
            // {
            //     keyword: RoleOfSubUserDefault.PURCHASER,
            //     name: 'Purchaser'
            // },
            {
                keyword: RoleOfSubUserDefault.DOCTOR,
                name: 'Doctor'
            },
            {
                keyword: RoleOfSubUserDefault.PHARMACIST,
                name: 'Pharmacist'
            },
            // {
            //     keyword: RoleOfSubUserDefault.NURSE,
            //     name: 'Nurse'
            // },
            // {
            //     keyword: RoleOfSubUserDefault.FINANCE,
            //     name: 'Finance'
            // },
            // {
            //     keyword: RoleOfSubUserDefault.BUSINESS_OWNER,
            //     name: 'Business Owner'
            // },
            // {
            //     keyword: RoleOfSubUserDefault.MANAGER,
            //     name: 'Manager'
            // }
        ];
    }
};

export const DesignationRequireLicenseFile = {
    DOCTOR: RoleOfSubUserDefault.DOCTOR,
    PHARMACIST: RoleOfSubUserDefault.PHARMACIST
};

/**
 * Nature of business group
 */
export const NatureOfBusinessDefault = {
    GetName(key, items) {
        let result = '';

        if (items && items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].keyword === key) {
                    result = items[i].name;
                    break;
                }
            }
        }

        return result;
    },
    ListAvailable() {
        return [
            {
                keyword: 'Clinic - Private',
                name: 'Clinic - Private',
                customerGroup: [
                    {
                        keyword: 'Clinic - Private',
                        name: 'Clinic - Private'
                    }
                ]
            },
            {
                keyword: 'Clinic - Public',
                name: 'Clinic - Public',
                customerGroup: [
                    {
                        keyword: 'Clinic - Public',
                        name: 'Clinic - Public'
                    }
                ]
            },
            {
                keyword: 'Retail Pharmacy - Independent',
                name: 'Retail Pharmacy - Independent',
                customerGroup: [
                    {
                        keyword: 'Retail Pharmacy - Independent',
                        name: 'Retail Pharmacy - Independent'
                    }
                ]
            },
            {
                keyword: 'Retail Pharmacy - Chain',
                name: 'Retail Pharmacy - Chain',
                customerGroup: [
                    {
                        keyword: 'Retail Pharmacy - Chain',
                        name: 'Retail Pharmacy - Chain'
                    }
                ]
            },
            {
                keyword: 'Hospital - Private',
                name: 'Hospital - Private',
                customerGroup: [
                    {
                        keyword: 'Hospital - Private',
                        name: 'Hospital - Private'
                    }
                ]
            },
            {
                keyword: 'Hospital - Public',
                name: 'Hospital - Public',
                customerGroup: [
                    {
                        keyword: 'Hospital - Public',
                        name: 'Hospital - Public'
                    }
                ]
            },
            {
                keyword: 'Other',
                name: 'Other',
                customerGroup: [
                    {
                        keyword: 'Other',
                        name: 'Other'
                    }
                ]
            }
        ];
    }
};

export const SpecialtyDefault = {
    GetName(key, items) {
        let result = '';

        if (items && items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].keyword === key) {
                    result = items[i].name;
                    break;
                }
            }
        }

        return result;
    },
    ListAvailable() {
        return [
            {
                keyword: 'Allergy & Immunology',
                name: 'Allergy & Immunology'
            },
            {
                keyword: 'Anesthesiology',
                name: 'Anesthesiology'
            },
            {
                keyword: 'Cardiology',
                name: 'Cardiology'
            },
            {
                keyword: 'Dental',
                name: 'Dental'
            },
            {
                keyword: 'Emergency Medicine',
                name: 'Emergency Medicine'
            },
            {
                keyword: 'Family Medicine',
                name: 'Family Medicine'
            },
            {
                keyword: 'General Practitioner',
                name: 'General Practitioner'
            },
            {
                keyword: 'General Surgery',
                name: 'General Surgery'
            },
            {
                keyword: 'Hematology',
                name: 'Hematology'
            },
            {
                keyword: 'Internal Medicine',
                name: 'Internal Medicine'
            },
            {
                keyword: 'Neurology',
                name: 'Neurology'
            },
            {
                keyword: 'Obstetrics & Gynecology',
                name: 'Obstetrics & Gynecology'
            },
            {
                keyword: 'Oncology',
                name: 'Oncology'
            },
            {
                keyword: 'Ophthalmology',
                name: 'Ophthalmology'
            },
            {
                keyword: 'Orthopedic',
                name: 'Orthopedic'
            },
            {
                keyword: 'Otolaryngology',
                name: 'Otolaryngology'
            },
            {
                keyword: 'Pathology',
                name: 'Pathology'
            },
            {
                keyword: 'Pediatrics',
                name: 'Pediatrics'
            },
            {
                keyword: 'Pharmacist',
                name: 'Pharmacist'
            },
            {
                keyword: 'Plastic Surgery',
                name: 'Plastic Surgery'
            },
            {
                keyword: 'Psychiatry',
                name: 'Psychiatry'
            },
            {
                keyword: 'Radiology',
                name: 'Radiology'
            },
            {
                keyword: 'Rheumatology',
                name: 'Rheumatology'
            },
            {
                keyword: 'Urology',
                name: 'Urology'
            },
            {
                keyword: 'Veterinarian',
                name: 'Veterinarian'
            },
            {
                keyword: 'Other',
                name: 'Other'
            }
        ];
    }
};

export const OptionMenuSubUser = [
    { title: 'Edit', key: ActionOnRecord.EDIT, color: '' },
    ...OptionMenuDeleteItem
];

export const ConfigType = {
    SYSTEMS: 'systems',
    PROTECT_REGISTRATION_FORM: 'protect_registration_form'
};

export const ConfigTypes = {
    GetName(key) {
        let result = '';
        const allItems = ConfigTypes.ListAvailable();

        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i].key === key) {
                result = allItems[i].title;
                break;
            }
        }

        return result;
    },
    ListAvailable() {
        return [
            { title: 'Password Protect Registration Form', key: ConfigType.PROTECT_REGISTRATION_FORM, default: true }
        ];
    }
};

export const OptionMenuConfig = [
    ...ConfigStatusList,
    ...OptionMenuDeleteItem
];

export const RangeRecordsExportToFile = [2000, 3000, 4000, 5000];