import React, { useState } from "react";
import { RefIdModel } from "../../models/unified-customer.model";

const RenderRefId = ({ index, vendor, data, onDelete, onUpdate, onFind }) => {
  const [refIdModel, setRefIdModel] = useState(data ? data : new RefIdModel());
  const [customerCode, setCustomerCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleInputRefId = (e) => {
    refIdModel.refId = e.target.value;

    if (onUpdate) {
      onUpdate(index, refIdModel);
    }
  }

  // const handleSelectVendor = (e) => {
  //   refIdModel.vendorId = e.target.value;

  //   if (onUpdate) {
  //     onUpdate(index, refIdModel);
  //   }
  // }

  const isDisableInputRefId = () => {
    return true;  // vendor && vendor.vendorIdentityCallbackEndpoint;
  }

  const isEnableFindRefId = () => {
    return false;
    // return (refIdModel.refId == null || refIdModel.refId === '') && vendor && vendor.vendorIdentityCallbackEndpoint
  }

  const handleFindRefId = (e) => {
    e.preventDefault();

    if (onFind && (customerCode !== '' || phoneNumber !== '')) {
      onFind(index, customerCode, phoneNumber);
    }
  }

  return (
    <>
      <div className="col-xl-4">
        <div className="card">
          <div className="card-header">
            <label className="fs-6 text-primary">Ref. ID {index + 1}</label>
            {/* <div className="d-flex align-items-center">
              <button className="btn btn-sm fs-12" onClick={onDelete}>x Delete</button>
            </div> */}
          </div>
          <div className="card-body">
            <div className="basic-form">
              <div className="row">
                {isEnableFindRefId()
                  && (<div className="form-group mb-3 row">
                    <label className="col-lg-4 col-form-label text-primary" htmlFor={`val-find-customer-code-${index}`}>Enter code / phone number</label>
                    <div className="col-lg-3">
                      <input type="text" className="form-control" id={`val-find-customer-code-${index}`} name={`val-find-customer-code-${index}`} value={customerCode} onChange={(e) => setCustomerCode(e.target.value)} placeholder="Customer code" />
                    </div>
                    <div className="col-lg-3">
                      <input type="text" className="form-control" id={`val-find-phone-number-${index}`} name={`val-find-phone-number-${index}`} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Mobile phone" />
                    </div>
                    <div className="col-lg-2">
                      <button className="btn btn-primary light btn-sm mt-2" onClick={handleFindRefId}>Validate</button>
                    </div>
                  </div>)}
                <div className="form-group mb-3 row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-reference-id-${index}`}>Reference ID</label>
                  <div className="col-lg-8">
                    <input type="text" className={`form-control ${isDisableInputRefId() ? 'bg-disabled' : ''}`} id={`val-reference-id-${index}`} name={`val-reference-id-${index}`} value={refIdModel.refId} onChange={handleInputRefId} placeholder="" disabled={isDisableInputRefId()} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-reference-vendor-${index}`}>Vendor</label>
                  <div className="col-lg-8">
                    <input type="text" className={`form-control ${isDisableInputRefId() ? 'bg-disabled' : ''}`} id={`val-reference-vendor-${index}`} name={`val-reference-vendor-${index}`} value={vendor && vendor.companyName ? vendor.companyName : 'N/A'} onChange={handleInputRefId} placeholder="" disabled />
                    {/* <select className="form-control" id={`val-reference-vendor-${index}`} name={`val-reference-vendor-${index}`} value={refIdModel.vendorId} onChange={handleSelectVendor} disabled>
                      <option value="">N/A</option>
                      {vendors && vendors.length > 0 && (
                        (vendors.map((v, i) => {
                          return (<option value={v.vendorId} key={i}>{v.companyName}</option>);
                        }))
                      )}
                    </select> */}
                  </div>
                </div>
                {refIdModel.accountType ? <>
                  <div className="form-group mb-3 row">
                  <label className="col-lg-4 col-form-label" htmlFor={`val-account-type-${index}`}>Account Type</label>
                  <div className="col-lg-8">
                    <input type="text" className="form-control bg-disabled" id={`val-account-type-${index}`} name={`val-account-type-${index}`} value={refIdModel.accountType} placeholder="" disabled />
                  </div>
                </div>
                </>:<></>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RenderRefId;
