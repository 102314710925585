import { toast } from 'react-toastify';
import { CountryCodes, TIME_ZONE_DEFAULT } from '../jsx/constant/AppConstant';
// import swal from 'sweetalert';
import * as moment from 'moment-timezone';

export function generateKey(pre) {
  return pre ? `${pre}_${new Date().getTime()}` : new Date().getTime();
}

/**
 * Validate phone number format.
 * @param {*} phone 
 * @param {*} countryCode 
 * @param {*} isFormatVN
 * @returns 
 */
export function validatePhoneNumber(phone, countryCode = '+84', isFormatVN = true) {
  if (phone == null || phone === '') {
    return false;
  }

  const phoneFormatted = reFormatPhoneNumber(phone, countryCode);
  const phoneStr = phoneFormatted.replace(countryCode, '');
  // Regex for phone number
  const reg = /^(([0-9]{8})|([0-9]{9})|([0-9]{10}))$/;   // Singapore, Malaysia
  // const re8 = /^([0-9]{8})$/;   // Singapore
  const re9 = /^([0-9]{9})$/;

  return isFormatVN ? re9.test(phoneStr) : reg.test(phoneStr);  // re8.test(phoneStr);
}

/**
 * Clear and re-format phone number
 * @param {*} phoneNumber 
 * @param {*} countryCode 
 * @returns 
 */
export function reFormatPhoneNumber(phoneNumber, countryCode = '+84') {
  if (phoneNumber == null || phoneNumber === '')
    return '';

  const countryCodeNonPrefix = countryCode.replace('+', '');
  let phoneFormatted = phoneNumber.toString().replace('+', '');

  // Remove country code
  const indexPrefix = phoneFormatted.indexOf(countryCodeNonPrefix);
  if (indexPrefix === 0) {
    phoneFormatted = phoneFormatted.substring(countryCodeNonPrefix.length, phoneFormatted.length);
  }
  // Remove prefix (digit 0)
  while (phoneFormatted.charAt(0) === '0')
    phoneFormatted = phoneFormatted.slice(1);

  return `${countryCode}${phoneFormatted}`;
}

/**
 * Validate email format.
 * @param {*} email
 * @returns 
 */
export function validateEmail(email) {
  if (email == null || email === '') {
    return false;
  }

  // Regex for validating email.
  let re = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
  // let re = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

  return re.test(email);
}

export function setLocalData(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    // console.log("error", error)
  }
}

export function removeLocalData(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    // console.log("error", error)
  }
  return null;
}

export function getLocalData(key) {
  try {
    let data = localStorage.getItem(key);
    return data;
  } catch (error) {
    // console.log("error", error)
  }
  return null;
}

export function isValidValue(value) {
  if (typeof value === "undefined" || value === null || value === "") {
    return false;
  }
  return true;
}

export function hasProperty(object, key) {
  if (Array.isArray(object) || typeof key != "string" || !object) {
    return false;
  } else {
    return object.hasOwnProperty(key);
  }
}

export function isValidObject(items) {
  if (Object.keys(items).length > 0) {
    return true;
  } else {
    return false;
  }
}

export function getValueFromObject(object, key) {
  if (hasProperty(object, key)) {
    if (isValidValue(object[key])) {
      return object[key];
    }
  }
  return "";
}

/**
 * Parse query string
 * @param data
 * @returns {string}
 */
export function parseFilterToString(data) {
  let str = '';
  if (data) {
    str = new URLSearchParams(data).toString();
  }

  return str;
}

/**
 * Make toast options default
 * @param {autoClose, position}
 * @returns {}
 */
export function makeToastOptions(autoClose = 2000, position = 'top-right') {
  return {
    position: position,
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };
}

/**
 * Show toast alert
 * @param message 
 */
export function showToast(message) {
  toast.info(message, makeToastOptions());
  // swal("Info", message, 'info');
}

/**
 * Show toast alert
 * @param message 
 */
export function showToastSuccess(message) {
  toast.success(message, makeToastOptions());
  // swal("Info", message, 'success');
}

/**
 * Show toast alert
 * @param message 
 */
export function showToastError(message) {
  toast.error(message, makeToastOptions());
  // swal("Error", message, 'error');
}

/**
 * Show toast alert
 * @param message 
 */
export function showToastWarning(message) {
  toast.warning(message, makeToastOptions());
  // swal("Warning", message, 'warning');
}

/**
 * Make mobile number format with country code
 * @param {*} countryCode
 * @returns 
 */
export function makeMobileNumberFormatHint(countryCode) {
  let formatStr = '';

  switch (countryCode) {
    case CountryCodes.VIETNAM_COUNTRY_CODE:
      formatStr = `${CountryCodes.VIETNAM_COUNTRY_CODE}123456789`;
      break;

    case CountryCodes.SINGAPORE_COUNTRY_CODE:
      formatStr = `${CountryCodes.SINGAPORE_COUNTRY_CODE}12345678`;
      break;

    case CountryCodes.MALAYSIA_COUNTRY_CODE:
      formatStr = `${CountryCodes.MALAYSIA_COUNTRY_CODE}12345678`;
      break;

    default:
      formatStr = `${countryCode}123456789`;
      break;
  }

  return `i.e: ${formatStr}`;
};

/**
 * Make email format hint
 * @returns 
 */
export function makeEmailFormatHint() {
  return `i.e: example@email.com`;
};

/**
 * Compare the different
 * @param {*} infoA 
 * @param {*} infoB 
 * @returns 
 */
export function hasDifferent(infoA, infoB) {
  if (infoA == null && infoB == null)
    return false;

  return infoA !== infoB;
};

/**
 * format date use at slot time
 * @param date
 * @param format
 * @returns {any|string}
 */
export function toFormatDate(date, format = '') {
  const isoDate = moment(date).toISOString();

  return format ? moment(isoDate).format(format) : moment(isoDate);
}

/**
 * Convert date to format with timezone
 * @param {*} date : ISO format in UTC
 * @param {*} format 
 * @param {*} timezone 
 * @returns 
 */
export function toFormatDateWithTimezone(date, format = '', timezone = TIME_ZONE_DEFAULT) {
  const isoDate = moment(date).toISOString();
  const dateWithTimezone = timezone ? moment.tz(isoDate, timezone) : moment(isoDate);

  return format ? dateWithTimezone.format(format) : dateWithTimezone;
}

export function getMinDateFilter(lastMonth) {
  let curDate = new Date();
  let minDate = new Date();
  minDate.setMonth(curDate.getMonth() - lastMonth);

  return toFormatDate(minDate, "YYYY-MM-DD");
}

export function getMaxDateFilter() {
  let curDate = new Date();

  const result = toFormatDate(curDate, "YYYY-MM-DD");

  return result;
}